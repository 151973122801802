const en = {
    fist_text: 'Stock Market Legend - Zhu Jiahong',
    tow_text: 'Retail investors can also be winners, getting rich through the stock market is definitely not just a rural legend!',
    third_text: 'Join the LINE group for free, and we\'ll take you from zero to profit!!',
    fourth_text: 'Three major features of the LINE group, reasons you absolutely can\'t miss:',
    fifth_text: '[Guaranteed Free to Join the Group]',
    sixth_text: '[Complete Breakdown of the Xiao Family\'s Turnaround Strategies]',
    seventh_text: '[Market Analysis Every Morning at 9 AM]',
    eighth_text: '[Monthly Selection of Potential Stocks]',
    ninth_text: 'Click the button below',
    tenth_text: 'Join the LINE group for free, and we\'ll take you from zero to profit!!',
}
export default en