// i18n.js
import { createI18n } from 'vue-i18n';
// import { en, fr } from './translations';
//
// const messages = {
//     en,
//     fr,
// };
import en from "@/language/package/en"
import jp from "@/language/package/japan"
import tr from "@/language/package/traditional"
const messages = {
    en,
    jp,
    tr
}
const i18n = createI18n({
    locale: 'tr', // 设置默认语言
    fallbackLocale: 'tr', // 设置后备语言
    messages, // 语言资源
});

export default i18n;