const ja = {
    fist_text: '株式市場の伝説 - 朱家泓',
    tow_text: '個人投資家も勝者になれる、株式市場で富を築くことは田舎の伝説では決してない！',
    third_text: 'LINEグループに無料で参加し、ゼロから利益を得るまでをサポートします!!',
    fourth_text: 'LINEグループの3つの大きな特徴、絶対に見逃せない理由：',
    fifth_text: '【グループへの参加は完全無料】',
    sixth_text: '【蕭家軍の転換操作を完全解説】',
    seventh_text: '【毎朝9時に市場分析】',
    eighth_text: '【毎月の注目銘柄を厳選して紹介】',
    ninth_text: '下のボタンをクリック',
    tenth_text: 'LINEグループに無料で参加し、ゼロから利益を得るまでをサポートします!!',
}
export default ja
