// import { createRouter, createWebHistory } from 'vue-router';
//
// // 定义路由
// const routes = [
//     {
//         path: '/',
//         name: 'Home',
//         component: () => import('@/components/HelloWorld.vue'),
//     },
//     {
//         path: '/LoginPage',
//         name: 'LoginPage',
//         component: () => import('@/components/LoginPage.vue'),
//     },
//     {
//         path: '/LinkPage',
//         name: 'LinkPage',
//         component: () => import('@/components/LinkPage.vue'),
//     },
//     {
//         path: '/404',
//         name: '404Page',
//         component: () => import('@/components/404Page.vue'),
//     }
//     // 更多路由...
// ];
//
// // 创建路由实例
// const router = createRouter({
//     history: createWebHistory(process.env.BASE_URL),
//     routes
// });
// const allowedLocations = [
//     { state: 'CA', cities: ['Los Angeles', 'San Francisco', 'Orange County'] },
//     { state: 'TX', cities: ['Dallas', 'Fort Worth'] },
//     { state: 'NY', cities: ['New York'] },
//     { state: 'FL', cities: ['Miami', 'Broward County'] },
//     { state: 'IL', cities: ['Chicago'] },
//     { state: 'PA', cities: ['Philadelphia'] },
//     { state: 'GA', cities: ['Atlanta'] },
//     { state: 'WA', cities: ['Washington', 'Seattle'] },
//     { state: 'MA', cities: ['Boston', 'Cambridge'] },
//     { state: 'MD', cities: ['Montgomery County', 'Frederick'] },
//     { state: 'MI', cities: ['Detroit'] },
//     { state: 'DE', cities: ['Wilmington'] },
// //
//     { state: 'BJ', cities: ['Beijing'] },
//     { state: 'SH', cities: ['Shanghai'] },
//     { state: 'GD', cities: ['Guangzhou', 'Shenzhen'] },
// ];
//
// // 缓存检查结果，避免重复加载
// let locationCheckResult = null;
//
// async function checkLocation() {
//     if (locationCheckResult !== null) {
//         return locationCheckResult;
//     }
//
//     return new Promise((resolve) => {
//         navigator.geolocation.getCurrentPosition(async (position) => {
//             const { latitude, longitude } = position.coords;
//             const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=YOUR_GOOGLE_MAPS_API_KEY`);
//             const data = await response.json();
//             const addressComponents = data.results[0].address_components;
//             const location = {
//                 city: '',
//                 state: '',
//             };
//             addressComponents.forEach((component) => {
//                 if (component.types.includes('locality')) {
//                     location.city = component.long_name;
//                 }
//                 if (component.types.includes('administrative_area_level_1')) {
//                     location.state = component.short_name;
//                 }
//             });
//
//             locationCheckResult = isAllowedLocation(location);
//             resolve(locationCheckResult);
//         }, () => {
//             // 如果无法获取地理位置，则拒绝访问
//             locationCheckResult = false;
//             resolve(locationCheckResult);
//         });
//     });
// }
//
// function isAllowedLocation(location) {
//     return allowedLocations.some(
//         (allowedLocation) =>
//             allowedLocation.state === location.state &&
//             allowedLocation.cities.includes(location.city)
//     );
// }
//
// router.beforeEach(async (to, from, next) => {
//     // 从 localStorage 获取登录状态
//     let loginState = localStorage.getItem("loginState");
//
//     // 从 localStorage 获取访问次数
//     let countStr = localStorage.getItem("cound"); // 确保键名正确
//     let count = countStr ? parseInt(countStr, 10) : 0; // 默认访问次数为0
//
//   let locationF =  await checkLocation()
//     console.log(locationF)
//    if(!locationF && to.path === '/'){
//       next("/404")
//        return
//    }
//
//     // 检查是否是 /404 路径
//     if (to.path === '/404') {
//         next(); // 如果已经是 /404 页面，直接放行
//         return;
//     }
//     if(to.path === '/LoginPage'){
//         next();
//     }
//     if(to.path === '/LinkPage' && loginState === 'true'){
//         next()
//     }
//
//     if (count <= 2) {
//         if (loginState !== 'true') {
//             if (to.path === '/') {
//                 next();
//             } else {
//                 next('/');
//             }
//         } else {
//             // loginState 存在且为 'true'，直接放行
//             next();
//         }
//     } else {
//         // 超过访问次数限制，重定向到 404 页面
//         next('/404');
//     }
// });
//
//
// export default router;
//
//
//


import { createRouter, createWebHistory } from 'vue-router';

// 定义路由
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/components/HelloWorld.vue'),
    },
    {
        path: '/LoginPage',
        name: 'LoginPage',
        component: () => import('@/components/LoginPage.vue'),
    },
    {
        path: '/LinkPage',
        name: 'LinkPage',
        component: () => import('@/components/LinkPage.vue'),
    },
    {
        path: '/404',
        name: '404Page',
        component: () => import('@/components/404Page.vue'),
    }
    // 更多路由...
];

// 创建路由实例
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});



// 缓存检查结果，避免重复加载


router.beforeEach(async (to, from, next) => {
    if(to.path === '/'){
        next()
    }if(to.path === '/LoginPage'){
        next()
    }if(to.path === '/LinkPage'){
        let loginState = localStorage.getItem("loginState");
        if(loginState === 'true'){
            next()
        }else{
            next('/LoginPage')
        }
    }if(to.path === '/404'){
        next()
    }
  next();
});

export default router;
