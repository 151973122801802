const tr = {
   fist_text:'股市傳奇 - 朱家泓',
   tow_text:'散戶也能是贏家，靠股市致富絕對不是鄉野傳奇！',
   third_text:'免費加入LINE群組，帶你從0到獲利!!',
   fourth_text:'LINE社團三大特色，給你絕不能錯過的理由:',
   fifth_text:'【加入社團保證免費】',
   sixth_text:'【完整解構蕭家軍轉折操作】',
   seventh_text:'【每早9點準時分析盤勢】',
   eighth_text:'【每月精選潛力股分享】',
   ninth_text:'點擊下方按鈕',
   tenth_text:'免費加入LINE群組，帶你從0到獲利!!',

}
export default tr
